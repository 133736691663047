<template>
    <span>{{name}}</span>
</template>

<script>
export default {
    name: "ProjectName",
    props: {
        value: {type: String}
    },
    computed: {
        project(){
            return this.$store.state.projects.data[this.value];
        },
        name(){
            if( this.project ){
                return this.project.client_name
            }
            return "";
        },
        
    }
}
</script>