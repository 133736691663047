<template>
	<v-form v-model="valid">
		<v-card flat>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="6">
						<v-autocomplete
							outlined
							clearable
							v-model="projects"
							:items="projectItems"
							multiple
							required
							:rules="projectRules"
							label="Projects"
							hide-details
						></v-autocomplete>
				<small class="info--text">Select one or more projects to continue</small>
					</v-col>
					<v-col cols="12" sm="3">
						<v-select
							label="Status"
							outlined
							clearable
							v-model="appointment_status"
							:items="appointment_status_items"
						>
						</v-select>
					</v-col>
					<v-col cols="12" sm="3">
						<mw-date-picker
							v-model="start"
							format="DD/MM/YYYY"
							label="Date Start"
						></mw-date-picker>
						<mw-date-picker
							v-model="end"
							format="DD/MM/YYYY"
							label="Date End"
						></mw-date-picker>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn :disabled="!valid" @click="submit" color="primary"
					>Submit</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-form>
</template>
<script>
import MwDatePicker from "@c/ui/MwDatePicker";
export default {
	name: "Filters",
	props: {
		value: { type: Object },
		projectItems: {type: Array }
	},
	data: () => {
		return {
			valid: null,
			projectRules: [
				(v) => !!v || "Project is required",
				(v) => v.length < 10 || "Select a maximum of 10 projects",
			],
			appointment_status_items: [
				{ text: "Complete", value: "complete" },
				{ text: "Cancelled", value: "cancelled" },
				{ text: "Cancelled/Client", value: "cancelled-client" },
				{ text: "New", value: "new" },
				{ text: "Hold", value: "hold" },
			],
			appointment_status: null,
			start: null, //
			end: null,
			projects: null,
			statuses: [],
			status_items: [
				{ text: "New", value: "new" },
				{ text: "Paid", value: "paid" },
				{ text: "Ready", value: "ready" },
				{ text: "Unpaid", value: "unpaid" },
				{ text: "Processed", value: "processed" },
				{ text: "Contacted", value: "contacted" },
				{ text: "Hold", value: "hold" },
			],
		};
	},
	components: {
		MwDatePicker,
	},
	computed: {
		filters() {
			let filters = {
				projects: this.projects,
				appointment_status: this.appointment_status,
				start: this.start,
				end: this.end,
			};
			return filters;
		},
		
	},
	methods: {
		submit() {
			this.$emit("input", this.filters );
		},
	},
	created() {
		for (var key in this.value) {
			this[key] = this.value[key];
		}
	},
};
</script>
