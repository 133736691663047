<template>
    <span>{{date}} <span v-if="time">{{timeV}}</span></span>
</template>

<script>
export default {
    name: "AppointmentStart",
    props: {
        value: {type: Object},
        time: {time: Boolean, default: true }
    },
    computed: {
        dt(){
            try {
                return this.value.toDate()
            }
            catch {
                return false;
            }
        },
        date(){
            if( this.dt ){
                return this.dt.toLocaleDateString()
            }
            return "";
        },
        timeV(){
            if( this.dt ){
                return this.dt.toLocaleTimeString({}, { hour: "2-digit", minute: "2-digit" })
            }
            return "";
        }
    }
}
</script>